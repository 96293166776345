import React from "react";

const Home = () => (
    <div className="container">
        <div className="jumbotron mt-5">
            <h1 className="display-4">Welcome!</h1>
            
        </div>
    </div>
);

export default Home;